import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

// import SiteImg1 from "../assets/img/component-site.png"



const HowitworksPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout type="how-it-works">
        <SEO title="How it works" />


        <section className="finapp-preview-section">

          <h1>Give more control to your customers</h1>

          <div className="uk-grid-small" data-uk-grid>
            <div className="section-app-window uk-width-2-3@m uk-text-center uk-flex-last@m">
              <iframe src="https://finapp.phaeria.net/717ff2ad-c4c1-4c16-aba8-bdf8a7fcec52-9TI0YT90JXI6" title="FinApp" width="100%" height="100%"></iframe>
            </div>
    
            <div className="uk-width-1-3@m ">
              <div className="uk-child-width-1-1 section-content uk-grid-row-medium uk-grid-column-collapse@m" data-uk-grid>
                <div className="card uk-card uk-card-body uk-grid-match">Set your proposal in minutes</div>
                <div className="card uk-card uk-card-body uk-grid-match">Customer can agree on required terms</div>
                <div className="card uk-card uk-card-body uk-grid-match">Have the ability to e-sign the proposal</div>
                <div className="card card-cta uk-card uk-card-body uk-grid-match">Start your first proposal<a href="#0" className="cta-primary-btn">Free Sign-up</a></div>
              </div>
            </div>
          </div>
          
        </section>

        <section className="finapp-preview-section">

          <h1>Create and send emails in a fingersnap</h1>

          <div className="uk-grid-small" data-uk-grid>
            <div className="section-app-window uk-width-2-3@m uk-text-center">
              <iframe src="https://finapp.phaeria.net/717ff2ad-c4c1-4c16-aba8-bdf8a7fcec52-9TI0YT90JXI6" title="FinApp" width="100%" height="100%"></iframe>
            </div>
    
            <div className="uk-width-1-3@m ">
              <div className="uk-child-width-1-1 section-content uk-grid-row-medium uk-grid-column-small" data-uk-grid>
                <div className="card uk-card uk-card-body uk-grid-match">Set your proposal in minutes</div>
                <div className="card uk-card uk-card-body uk-grid-match">Customer can agree on required terms</div>
                <div className="card uk-card uk-card-body uk-grid-match">Have the ability to e-sign the proposal</div>
                <div className="card card-cta uk-card uk-card-body uk-grid-match">Start your first proposal<a href="#0" className="cta-primary-btn">Free Sign-up</a></div>
              </div>
            </div>
          </div>
          
        </section>




        <CTA type="cta" url="#0"/>

        <section className="faq-section">
          <div className="faq-container">
            <div className="uk-card uk-card-muted uk-width-1-2@m uk-align-center">
              <div>
                <h3 className="uk-text-center">FAQs</h3>
              </div>
              <div data-uk-accordion className="faq-accordion">
                
                    <li>
                      <a className="uk-accordion-title faq-question" href="#0">
                        Here's the question
                      </a>
                      <div className="uk-accordion-content faq-answer">Here's the answer</div>
                    </li>
                    <li>
                      <a className="uk-accordion-title faq-question" href="#0">
                        Here's the second question
                      </a>
                      <div className="uk-accordion-content faq-answer">Here's the answer</div>
                    </li>
                 
              </div>
            </div>
          </div>
        </section>

        
        

      </Layout>

    )}
  
  />
)

export default HowitworksPage
